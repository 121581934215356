.dropdown {
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: flex-start;
    gap: 12px;
    position: relative;
  }
  
  .dropdown .frame {
    align-items: flex-start;
    display: flex;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
  
  .dropdown .text-wrapper {
    position: relative;
    flex: 1;
    margin-top: -1px;
    font-family: "Lato-Regular", Helvetica;
    font-weight: 400;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dropdown .div {
    align-items: center;
    gap: 20px;
    padding: 12px 20px;
    background-color: #393a73;
    border-radius: 5px;
    border: 1px solid;
    border-color: #ffffff;
    display: flex;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
  
  .dropdown .utility-icons-c {
    position: absolute;
    width: 32px;
    height: 32px;
    color: #ffffff;
    right: 6px;
    /* font-size: large; */
    /* box-sizing: border-box; */
  }
  