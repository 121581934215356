@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */
/* styles.css */
/* styles.css */
/* .div1 {
  width: 50%;
  background-color: blue;
  height: 50vh; 
  float: left;
} */

/* .div2 {
  width: 50%;
  background-color: red;
  height: 50vh; 
  float: left;
} */

/* .div3 {
  width: 100%;
  background-color: green;
  height: 50vh;
  clear: left; 
} */
/* datepicker-styles.css */
/* datepicker-styles.css */
.smallDateTimePicker {
  font-size: 0.7rem !important;
  width: 40px !important;
  min-width: 180px !important; /* Adjust the min-width according to your needs */
  padding: 4px !important;
  margin-top: 0 !important;
}

/* ol, ul, menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table-caption;
} */

/* .custom-dropdown .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
height: 200px;
} */
