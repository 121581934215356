.battery {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
}

.battery-body {
    display: flex;
    justify-content: space-between;
    width: 150px;
    height: 50px;
    border: 2px solid #000;
    padding: 2px;
    position: relative;
    margin-right: 10px;
}

.battery-bar {
    flex: 1;
    margin: 0 2px;
    /* background-color: #ccc; */
    height: 100%;
    transition: background-color 0.3s ease-in-out;
}

.battery-bar.filled {
    background-color: #46B648;
}

.battery-bar.low {
    background-color: #EE2C37;
  }
  
  .battery-bar.medium {
    background-color: #F36831;
  }
  
  .battery-bar.high {
    background-color: #FCD90E;
  }
  

.battery-cap {
    width: 10px;
    height: 30px;
    border: 2px solid #000;
    border-left: none;
    margin-left: -10px;
}

.battery-percentage {
    font-size: 1.2em;
    margin-left: 10px;
}
